import fetch from 'auth/FetchInterceptor'

const ModuleService = {}

ModuleService.createModule = function (data) {
	return fetch({
		url: 'module-management',
		method: 'post',
		data
	})
}
//
ModuleService.getModule = function () {
	
	return fetch({
		url: 'module-management',
		method: 'get',
	})
}
//
ModuleService.showModule = function (id) {
	
	return fetch({
		url: `module-management/${id}`,
		method: 'get',
	})
}

export default ModuleService;