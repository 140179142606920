const dev = {
	SOCKET_ENDPOINT_URL: 'http://cobalt.test/back/api/',
	API_ENDPOINT_URL: 'http://cobalt.test/back/api/auth/',
	FILE_ENDPOINT_URL: 'http://cobalt.test/back/storage/'
};

const prod = {
	SOCKET_ENDPOINT_URL: 'https://cobalt.threeapples.ca/back/api/',
	API_ENDPOINT_URL: 'https://cobalt.threeapples.ca/back/api/auth/',
	FILE_ENDPOINT_URL: 'https://cobalt.threeapples.ca/'
};

const test = {
	SOCKET_ENDPOINT_URL: 'http://cobalt.test/back/api/',
	API_ENDPOINT_URL: 'http://cobalt.test/back/api/auth/',
	FILE_ENDPOINT_URL: 'http://cobalt.test/back/storage/'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
