import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CREATE_BLOG,DELETE_BLOG,GET_BLOG, NAME_OF_BLOG_SLICE } from 'constants/AppConstants';
import AllBlogs from 'services/AllBlogs/AllBlogs';

export const initialState = {
  AllBlogsErrors       : {},
  AllBlogsShowMessage  : null,
  AllBlogsResult       : [],
  AllBlogsButtonSpiner : false,
  AllBlogsAddDrawer    : false,
  AllBlogsEditData     : [],
  AllBlogsButtonAndModelLabel  : 'Add Blog',
};

export const createAllBlogs = createAsyncThunk(
    CREATE_BLOG,
  async (data) => {
    try {
      const response = await AllBlogs.createAllBlogs(data);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getAllBlogs = createAsyncThunk(
    GET_BLOG,
  async (data) => {
    try {
      const response = await AllBlogs.getAllBlogs(data);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteAllBlogs = createAsyncThunk(
  DELETE_BLOG,
  async (data) => {
    try {
      const response = await AllBlogs.deleteAllBlogs(data);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageAllBlogsSlice = createSlice({
  name: NAME_OF_BLOG_SLICE,
  initialState,
  reducers: {
    AllBlogsAddDrawerStatus: (state, action) => {
      state.AllBlogsErrors      = {};
      state.AllBlogsAddDrawer   = action.payload;
      state.AllBlogsEditData    = [];
      state.AllBlogsButtonAndModelLabel = 'Add Blog';
    },
    AllBlogsEditWithDrawerStatus: (state, action) => {
      console.log(action);
      state.AllBlogsErrors = {};
      state.AllBlogsAddDrawer = true;
      state.AllBlogsEditData = action.payload;
      state.AllBlogsButtonAndModelLabel = 'Edit Blog';

   }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAllBlogs.pending, (state, action) => {
        state.AllBlogsButtonSpiner = true;
      }).addCase(createAllBlogs.fulfilled, (state, action) => {
        state.AllBlogsButtonSpiner = false;
        state.AllBlogsErrors = {}
      }).addCase(createAllBlogs.rejected, (state, action) => {
        state.AllBlogsErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.AllBlogsShowMessage = true; // Set the showMessage flag to display the errors
        state.AllBlogsButtonSpiner = false;
      }).addCase(getAllBlogs.pending, (state, action) => {
        state.AllBlogsButtonSpiner = true;
      }).addCase(getAllBlogs.fulfilled, (state, action) => {
        state.AllBlogsButtonSpiner = false;
        state.AllBlogsResult = action.payload;
        state.AllBlogsErrors = {}
      }).addCase(getAllBlogs.rejected, (state, action) => {
        state.AllBlogsErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.AllBlogsShowMessage = true; // Set the showMessage flag to display the errors
        state.AllBlogsButtonSpiner = false;
      });
  },
});

export const {  AllBlogsAddDrawerStatus, AllBlogsEditWithDrawerStatus } = manageAllBlogsSlice.actions;

export default manageAllBlogsSlice.reducer;
