import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  NAME_OF_MODULE_SLICE,
  GET_MODULE_WITH_PERMISSION,
} from "constants/AppConstants";
import ModuleService from "services/Modules/ModuleService";
import CommonService from "services/CommonService/CommonService";

export const initialState = {
  errors: {},
  showMessage: null,
  moduleResult: [],
  moduleButtonSpiner: false,
  AddModuleDrawer: false,
  editModuleData: [],
  modalLabel: "Add Module",
  parentModuelTree: [],
  getModuleWithPermissionResult: [],
};

export const createModule = createAsyncThunk("createModule", async (data) => {
  try {
    const response = await ModuleService.createModule(data);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const getModule = createAsyncThunk("getModule", async () => {
  try {
    const response = await ModuleService.getModule();
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});
//
export const getModuleWithPermission = createAsyncThunk(
  "getModuleWithPermission",
  async () => {
    try {
      const response = await CommonService.getAll(GET_MODULE_WITH_PERMISSION);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);
//
export const showModule = createAsyncThunk("showModule", async (data) => {
  try {
    if (data == null || data == "") {
      return false;
    }
    const response = await ModuleService.showModule(data);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const manageModuleSlice = createSlice({
  name: NAME_OF_MODULE_SLICE,
  initialState,
  reducers: {
    AddModuleDrawerStatus: (state, action) => {
      state.AddModuleDrawer = action.payload;
      state.editModuleData = [];
      state.modalLabel = "Add Module";
    },
    EditModule: (state, action) => {
      state.AddModuleDrawer = true;
      state.editModuleData = action.payload;
      state.modalLabel = "Edit Permission";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getModuleWithPermission.pending, (state, action) => {
        state.loading = true;
        state.buttonSpiner = true;
      })
      .addCase(getModuleWithPermission.fulfilled, (state, action) => {
        state.getModuleWithPermissionResult = action.payload;
        state.errors = {};
      })
      .addCase(getModuleWithPermission.rejected, (state, action) => {
        state.errors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.showMessage = true; // Set the showMessage flag to display the errors
        state.buttonSpiner = false;
      })
      .addCase(createModule.pending, (state, action) => {
        state.loading = true;
        state.buttonSpiner = true;
      })
      .addCase(createModule.fulfilled, (state, action) => {
        state.buttonSpiner = false;
        state.errors = {};
      })
      .addCase(createModule.rejected, (state, action) => {
        state.errors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.showMessage = true; // Set the showMessage flag to display the errors
        state.buttonSpiner = false;
      })
      .addCase(getModule.pending, (state, action) => {
        state.loading = true;
        state.buttonSpiner = true;
      })
      .addCase(getModule.fulfilled, (state, action) => {
        state.moduleResult = action.payload;
        state.buttonSpiner = false;
      })
      .addCase(getModule.rejected, (state, action) => {
        state.errors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.showMessage = true; // Set the showMessage flag to display the errors
        state.buttonSpiner = false;
      })
      .addCase(showModule.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(showModule.fulfilled, (state, action) => {
        state.parentModuelTree = action.payload;
        state.buttonSpiner = false;
        action.payload
          ? (state.parentModuelTree = [action.payload])
          : (state.parentModuelTree = "");
      })
      .addCase(showModule.rejected, (state, action) => {
        state.errors = action.error.message
          ? JSON.parse(action.error.message)
          : {}; // Parse the error messages and store them in the state
        state.showMessage = true; // Set the showMessage flag to display the errors
        state.buttonSpiner = false;
      });
  },
});

export const { AddModuleDrawerStatus, EditModule } = manageModuleSlice.actions;

export default manageModuleSlice.reducer;
