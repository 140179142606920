import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CREATE_BLOG_CATEGORY,DELETE_BLOG_CATEGORY,GET_BLOG_CATEGORY, NAME_OF_BLOG_CATEGORY_SLICE } from 'constants/AppConstants';
import BlogCategoryservice from 'services/BlogCategory/BlogCategoryservice';

export const initialState = {
  BlogCategoryErrors       : {},
  BlogCategoryShowMessage  : null,
  BlogCategoryResult       : [],
  BlogCategoryButtonSpiner : false,
  BlogCategoryAddDrawer    : false,
  BlogCategoryEditData     : [],
  CategoryButtonAndModalLabel  : 'Add Blog Category',
};

export const createBlogCategory = createAsyncThunk(
    CREATE_BLOG_CATEGORY,
  async (data) => {
    try {
      const response = await BlogCategoryservice.createBlogCategory(data);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getBlogCategory = createAsyncThunk(
    GET_BLOG_CATEGORY,
  async (data) => {
    try {
      const response = await BlogCategoryservice.getBlogCategory(data);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteBlogCategory = createAsyncThunk(
  DELETE_BLOG_CATEGORY,
  async (data) => {
    try {
      const response = await BlogCategoryservice.deleteBlogCategory(data);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageBlogCategorySlice = createSlice({
  name: NAME_OF_BLOG_CATEGORY_SLICE,
  initialState,
  reducers: {
    BlogCategoryAddDrawerStatus: (state, action) => {
      state.BlogCategoryErrors      = {};
      state.BlogCategoryAddDrawer   = action.payload;
      state.BlogCategoryEditData    = [];
      state.CategoryButtonAndModalLabel = 'Add Blog Category';
    },
    BlogCategoryEditWithDrawerStatus: (state, action) => {
      console.log(action);
      state.BlogCategoryErrors = {};
      state.BlogCategoryAddDrawer = true;
      state.BlogCategoryEditData = action.payload;
      state.CategoryButtonAndModalLabel = 'Edit Blog Category';

   }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBlogCategory.pending, (state, action) => {
        state.BlogCategoryButtonSpiner = true;
      }).addCase(createBlogCategory.fulfilled, (state, action) => {
        state.BlogCategoryButtonSpiner = false;
        state.BlogCategoryErrors = {}
      }).addCase(createBlogCategory.rejected, (state, action) => {
        state.BlogCategoryErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.BlogCategoryShowMessage = true; // Set the showMessage flag to display the errors
        state.BlogCategoryButtonSpiner = false;
      }).addCase(getBlogCategory.pending, (state, action) => {
        state.BlogCategoryButtonSpiner = true;
      }).addCase(getBlogCategory.fulfilled, (state, action) => {
        state.BlogCategoryButtonSpiner = false;
        state.BlogCategoryResult = action.payload;
        state.BlogCategoryErrors = {}
      }).addCase(getBlogCategory.rejected, (state, action) => {
        state.BlogCategoryErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.BlogCategoryShowMessage = true; // Set the showMessage flag to display the errors
        state.BlogCategoryButtonSpiner = false;
      });
  },
});

export const {  BlogCategoryAddDrawerStatus, BlogCategoryEditWithDrawerStatus } = manageBlogCategorySlice.actions;

export default manageBlogCategorySlice.reducer;
