import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
//_import_slice_here
    import manageImportExcelSlice from './slices/ImportExcel/manageImportExcelSlice';
    import manageAllOrdersSlice from './slices/AllOrders/manageAllOrdersSlice';
    import manageOrderSlice from './slices/Order/manageOrderSlice';
    import manageCustomerSlice from './slices/Customer/manageCustomerSlice';
    import manageRolePermissionSlice from './slices/RolePermission/manageRolePermissionSlice';
    import manageProductCategorySlice from './slices/ProductCategory/manageProductCategorySlice';
    import manageProductSlice from './slices/Product/manageProductSlice';
    import manageOrganizationSlice from './slices/Organization/manageOrganizationSlice';
import manageSetupOrganizationSlice from "./slices/SetupOrganization/manageSetupOrganizationSlice";
import manageManageUserSlice from "./slices/ManageUser/manageManageUserSlice";

import manageModuleSlice from "./slices/ManageModuleSlice/manageModuleSlice";
import manageModuleGroupSlice from "./slices/ManageModuleGroupSlice/manageModuleGroupSlice";
import managePermissionSlice from "./slices/ManagePermissionSlice/managePermissionSlice";
import manageBlogCategorySlice from "./slices/ManageBlogCategorySlice/manageBlogCategorySlice";
import manageAllBlogsSlice from "./slices/ManageAllBlogsSlice/manageAllBlogsSlice";
import manageRoleSlice from "./slices/ManageRoleSlice/manageRoleSlice";
import managFluidSearchSlice from "./slices/FluidSearchSlice/managFluidSearchSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    manageModuleSlice,
    managePermissionSlice,
    manageModuleGroupSlice,
    manageBlogCategorySlice,
    manageAllBlogsSlice,
    manageRoleSlice,
    //_Register_slice_after
    manageImportExcelSlice,
    manageAllOrdersSlice,
    manageOrderSlice,
    manageCustomerSlice,
    manageRolePermissionSlice,
    manageProductCategorySlice,
    manageProductSlice,
    manageOrganizationSlice,
    manageSetupOrganizationSlice,
    manageManageUserSlice,

    managFluidSearchSlice,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
