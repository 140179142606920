import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  NAME_OF_SEARCH_SLICE } from 'constants/AppConstants';
import Common from 'services/CommonService/Common';

export const initialState = {
  AllBlogsErrors       : {},
  AllBlogsShowMessage  : null,
  AllBlogsResult       : [],
  AllBlogsButtonSpiner : false,
  AllBlogsAddDrawer    : false,
  AllBlogsEditData     : [],
  AllBlogsButtonAndModelLabel  : 'Add Blog',
};

export const createAllSearch = createAsyncThunk(
    'module/sear-from-all',
  async (data) => {
    try {
      console.log("from reduceer"+ data);
      const response = await Common.getAll(data, "all-search");
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);



export const manageAllBlogsSlice = createSlice({
  name: NAME_OF_SEARCH_SLICE,
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAllSearch.pending, (state, action) => {
        state.AllBlogsButtonSpiner = true;
      }).addCase(createAllSearch.fulfilled, (state, action) => {
        state.AllBlogsButtonSpiner = false;
        state.AllBlogsErrors = {}
      }).addCase(createAllSearch.rejected, (state, action) => {
        state.AllBlogsErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.AllBlogsShowMessage = true; // Set the showMessage flag to display the errors
        state.AllBlogsButtonSpiner = false;
      });
  },
});

export const {  AllBlogsAddDrawerStatus, AllBlogsEditWithDrawerStatus } = manageAllBlogsSlice.actions;

export default manageAllBlogsSlice.reducer;
