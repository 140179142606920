import fetch from 'auth/FetchInterceptor'

const BlogCategoryservice = {}

BlogCategoryservice.createBlogCategory = function (data) {
	if (data && data.hasOwnProperty('id')) {
	  return fetch({
		url: `blog-category-management/${data.id}`,
		method: 'put',
		data
	  });
	} else {
	  return fetch({
		url: 'blog-category-management',
		method: 'post',
		data
	  });
	}
  };
//
BlogCategoryservice.getBlogCategory = function () {
	
	return fetch({
		url: 'blog-category-management',
		method: 'get',
	})
}
//
BlogCategoryservice.showBlogCategory = function (id) {
	
	return fetch({
		url: `blog-category-management/${id}`,
		method: 'get',
	})
}
//
BlogCategoryservice.deleteBlogCategory = function (id) {
    return fetch({
        url: `blog-category-management/${id}`,
        method: 'delete',
    });
};


export default BlogCategoryservice;