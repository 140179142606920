export const CREATE_PERMISSION = "module/create_permission";
export const GET_PERMISSION = "module/get_permission";
export const DELETE_PERMISSION = "module/delete_permission";
export const NAME_OF_PERMISSION_SLICE = "managePermissionSlice";

export const NAME_OF_MODULE_SLICE = "manageModuleSlice";
export const GET_MODULE_WITH_PERMISSION = "get-module-with-permission";

export const NAME_OF_MODULE_GROUP_SLICE = "manageModuleGroupSlice";
export const CREATE_MODULE_GROUP = "module/create_moduel_group";
export const SHOW_MODULE = "module/show_module";

export const CREATE_BLOG_CATEGORY = "module/create_blog_category";
export const GET_BLOG_CATEGORY = "module/get_blog_category";
export const DELETE_BLOG_CATEGORY = "module/delete_blog_category";
export const NAME_OF_BLOG_CATEGORY_SLICE = "manageBlogCategorySlice";

export const CREATE_BLOG = "module/create_blog";
export const GET_BLOG = "module/get_blog";
export const DELETE_BLOG = "module/delete_blog";
export const NAME_OF_BLOG_SLICE = "manageAllBlogsSlice";

export const CREATE_ROLE = "module/create_role";
export const GET_ROLE = "module/get_role";
export const DELETE_ROLE = "module/delete_role";
export const NAME_OF_ROLE_SLICE = "manageRoleSlice";

//socket key for shake-hand with server-end application
export const REACT_APP_PUSHER_APP_KEY = "pusher_id";
export const REACT_APP_PUSHER_CLUSTER = "mt1";

export const NAME_OF_SEARCH_SLICE = "module/search";
export const APPLE_DATA_API_URL = "apple-data";
export const APPLE_DATA = "manageAppleDataSlice";

export const APPLE_DATAA_API_URL = "apple-dataa";
export const APPLE_DATAA = "manageAppleDataaSlice";

export const MANAGE_USER_API_URL = "manage-user";
export const MANAGE_USER_FILTER_API_URL = "manage-user-filter";
export const MANAGE_USER = "manageManageUserSlice";

export const MANAGE_LOOKUPS_API_URL = "manage-lookups";
export const MANAGE_LOOKUPS = "manageManageLookupsSlice";
export const SETUP_ORGANIZATION_API_URL = 'setup-organization'
export const SETUP_ORGANIZATION = 'manageSetupOrganizationSlice'

export const ORGANIZATION_API_URL = 'organization'
export const ORGANIZATION = 'manageOrganizationSlice'

export const PRODUCT_API_URL = 'product'
export const PRODUCT_Filter_API_URL = 'productFilter'
export const PRODUCT = 'manageProductSlice'

export const PRODUCT_CATEGORY_API_URL = 'product-category'
export const PRODUCT_CATEGORIES_API_URL = 'product-categories'
export const PRODUCT_CATEGORY = 'manageProductCategorySlice'

export const ROLE_PERMISSION_API_URL = 'role-permission'
export const ROLE_PERMISSION = 'manageRolePermissionSlice'

export const CUSTOMER_API_URL = 'customer'
export const CUSTOMER_ORDER_API_URL = 'customerOrder'
export const CUSTOMER_ORDER_FILTER_API_URL = 'customerFilter'
export const CUSTOMER_PRODUCT_API_URL = 'customerProducts'
export const CUSTOMER = 'manageCustomerSlice'

export const ORDER_API_URL = 'order'
export const ORDER_FILTER_API_URL = 'orderFilter'
export const ADDITIONAL_ORDER_API_URL = 'getAdditionalOrders'
export const ORDER_MESSAGE_API_URL = 'getOrderMessage'
export const ADDI_ORDER_MESSAGE_API_URL = 'getAdditionalOrderMessages'
export const ORDER = 'manageOrderSlice'

export const ALL_ORDERS_API_URL = 'all-orders'
export const ALL_ORDERS = 'manageAllOrdersSlice'

export const IMPORT_CUSTOMER_EXCEL_API_URL = 'import-customer-excel'
export const IMPORT_ORDER_EXCEL_API_URL = 'import-order-excel'
export const IMPORT_PRODUCT_EXCEL_API_URL = 'import-product-excel'
export const IMPORT_EXCEL = 'manageImportExcelSlice'

