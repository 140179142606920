import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {ROLE_PERMISSION, ROLE_PERMISSION_API_URL} from 'constants/AppConstants';
import CommonService from 'services/CommonService/CommonService';

export const initialState = {
  RolePermissionErrors       : {},
  RolePermissionShowMessage  : null,
  RolePermissionResult       : [],
  RolePermissionButtonSpiner : false,
  RolePermissionAddDrawer    : false,
  RolePermissionEditData     : [],
  RolePermissionButtonAndModelLabel  : 'Add Role Permission',
};

export const createRolePermission = createAsyncThunk(
    "createRolePermission",
  async (data) => {
    try {
      const response = await CommonService.createAndUpate(data, ROLE_PERMISSION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getRolePermission = createAsyncThunk(
    "getRolePermission",
  async (data) => {
    try {
      const response = await CommonService.getAll(ROLE_PERMISSION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteRolePermission = createAsyncThunk(
  "deleteRolePermission",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, ROLE_PERMISSION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageRolePermissionSlice = createSlice({
  name: ROLE_PERMISSION,
  initialState,
  reducers: {
    RolePermissionAddDrawerStatus: (state, action) => {
      state.RolePermissionErrors      = {};
      state.RolePermissionAddDrawer   = action.payload;
      state.RolePermissionEditData    = [];
      state.RolePermissionButtonAndModelLabel = 'Add Role Permission';
    },
    RolePermissionEditWithDrawerStatus: (state, action) => {
      console.log(action);
      state.RolePermissionErrors = {};
      state.RolePermissionAddDrawer = true;
      state.RolePermissionEditData = action.payload;
      state.RolePermissionButtonAndModelLabel = 'Edit Role Permission';

   }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRolePermission.pending, (state, action) => {
        state.RolePermissionButtonSpiner = true;
      }).addCase(createRolePermission.fulfilled, (state, action) => {
        state.RolePermissionButtonSpiner = false;
        state.RolePermissionErrors = {}
      }).addCase(createRolePermission.rejected, (state, action) => {
        state.RolePermissionErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.RolePermissionShowMessage = true; // Set the showMessage flag to display the errors
        state.RolePermissionButtonSpiner = false;
      }).addCase(getRolePermission.pending, (state, action) => {
        state.RolePermissionButtonSpiner = true;
      }).addCase(getRolePermission.fulfilled, (state, action) => {
        state.RolePermissionButtonSpiner = false;
        state.RolePermissionResult = action.payload;
        state.RolePermissionErrors = {}
      }).addCase(getRolePermission.rejected, (state, action) => {
        state.RolePermissionErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.RolePermissionShowMessage = true; // Set the showMessage flag to display the errors
        state.RolePermissionButtonSpiner = false;
      });
  },
});

export const {  RolePermissionAddDrawerStatus, RolePermissionEditWithDrawerStatus } = manageRolePermissionSlice.actions;

export default manageRolePermissionSlice.reducer;
