import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT, PRODUCT_API_URL, PRODUCT_Filter_API_URL } from 'constants/AppConstants';
import CommonService from 'services/CommonService/CommonService';

export const initialState = {
  ProductErrors: {},
  ProductShowMessage: null,
  ProductResult: [],
  ProductButtonSpiner: false,
  ProductAddDrawer: false,
  ProductEditData: [],
  ProductButtonAndModelLabel: 'Add Product',
};

export const createProduct = createAsyncThunk(
  "createProduct",
  async (data) => {
    try {
      const response = await CommonService.createAndUpate(data, PRODUCT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getProduct = createAsyncThunk(
  "getProduct",
  async (data) => {
    try {
      const response = await CommonService.getAll(PRODUCT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "deleteProduct",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, PRODUCT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);


export const getProductFilter = createAsyncThunk(
  "getProductFilter",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, PRODUCT_Filter_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageProductSlice = createSlice({
  name: PRODUCT,
  initialState,
  reducers: {
    ProductAddDrawerStatus: (state, action) => {
      state.ProductErrors = {};
      state.ProductAddDrawer = action.payload;
      state.ProductEditData = [];
      state.ProductButtonAndModelLabel = 'Add Product';
    },
    ProductEditWithDrawerStatus: (state, action) => {
      state.ProductErrors = {};
      state.ProductAddDrawer = true;
      state.ProductEditData = action.payload;
      state.ProductButtonAndModelLabel = 'Edit Product';

    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProduct.pending, (state, action) => {
        state.ProductButtonSpiner = true;
      }).addCase(createProduct.fulfilled, (state, action) => {
        state.ProductButtonSpiner = false;
        state.ProductErrors = {}
      }).addCase(createProduct.rejected, (state, action) => {
        state.ProductErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.ProductShowMessage = true; // Set the showMessage flag to display the errors
        state.ProductButtonSpiner = false;
      }).addCase(getProduct.pending, (state, action) => {
        state.ProductButtonSpiner = true;
      }).addCase(getProduct.fulfilled, (state, action) => {
        state.ProductButtonSpiner = false;
        state.ProductResult = action.payload;
      }).addCase(getProduct.rejected, (state, action) => {
        state.ProductErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.ProductShowMessage = true; // Set the showMessage flag to display the errors
        state.ProductButtonSpiner = false;
      })
      .addCase(getProductFilter.pending, (state, action) => {
        state.ProductButtonSpiner = true;
      }).addCase(getProductFilter.fulfilled, (state, action) => {
        state.ProductButtonSpiner = false;
        state.ProductResult = action.payload;
      }).addCase(getProductFilter.rejected, (state, action) => {
        state.ProductErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.ProductShowMessage = true; // Set the showMessage flag to display the errors
        state.ProductButtonSpiner = false;
      })

  },
});

export const { ProductAddDrawerStatus, ProductEditWithDrawerStatus } = manageProductSlice.actions;

export default manageProductSlice.reducer;
