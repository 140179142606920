import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ADDITIONAL_ORDER_API_URL, ADDI_ORDER_MESSAGE_API_URL, ORDER, ORDER_API_URL, ORDER_FILTER_API_URL, ORDER_MESSAGE_API_URL } from 'constants/AppConstants';
import CommonService from 'services/CommonService/CommonService';

export const initialState = {
  OrderErrors: {},
  OrderShowMessage: null,
  OrderResult: [],
  OrderButtonSpiner: false,
  OrderAddDrawer: false,
  OrderChatAddDrawer: false,
  OrderEditData: [],
  OrderProductQuality: [],
  productArray: [],
  OrderButtonAndModelLabel: 'Add Order',
  ReOrderErrors: {},
  ReOrderAddDrawer: false,
  ReOrderEditData: [],
  ReOrderProductQuality: [],
  ReProductArray: [],
  ReOrderButtonAndModelLabel: [],
  additionalOrderData: [],
  OrderChatData: [],
  orderDetailData: [],
  orderChat: [],
  OrderId: 0
};

export const createOrder = createAsyncThunk(
  "createOrder",
  async (data) => {
    try {
      const response = await CommonService.createAndUpate(data, ORDER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getOrder = createAsyncThunk(
  "getOrder",
  async (data) => {
    try {
      const response = await CommonService.getAll(ORDER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteOrder = createAsyncThunk(
  "deleteOrder",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, ORDER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getOrderDetail = createAsyncThunk(
  "getOrderDetail",
  async (data) => {
    try {
      const response = await CommonService.showOne(data, ORDER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getOrderFilter = createAsyncThunk(
  "getOrderFilter",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, ORDER_FILTER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getAdditionalOrder = createAsyncThunk(
  "getAdditionalOrder",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, ADDITIONAL_ORDER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const sendMessage = createAsyncThunk(
  "sendMessage",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, ORDER_MESSAGE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getAdditionalOrderMessages = createAsyncThunk(
  "getAdditionalOrderMessages",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, ADDI_ORDER_MESSAGE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageOrderSlice = createSlice({
  name: ORDER,
  initialState,
  reducers: {
    OrderAddDrawerStatus: (state, action) => {
      state.OrderErrors = {};
      state.OrderAddDrawer = action.payload;
      state.OrderProductQuality = [];
      state.OrderEditData = [];
      state.OrderButtonAndModelLabel = 'Add Order';
      state.productArray = [];
    },
    ReOrderAddDrawerStatus: (state, action) => {
      state.ReOrderErrors = {};
      state.ReOrderAddDrawer = action.payload;
      state.ReOrderProductQuality = [];
      state.ReOrderEditData = [];
      state.ReOrderButtonAndModelLabel = 'Add Order';
      state.ReProductArray = [];
    },
    OrderProductStatus: (state, action) => {
      state.OrderProductQuality = action.payload;
    },
    OrderEditWithDrawerStatus: (state, action) => {
      state.OrderErrors = {};
      state.OrderAddDrawer = true;
      state.OrderEditData = action.payload;
      state.productArray = action?.payload?.product.map(data => data.id);
      state.OrderProductQuality = action.payload.product;
      state.OrderButtonAndModelLabel = 'Edit Order';
    },
    OrderReOrderWithDrawerStatus: (state, action) => {
      state.ReOrderErrors = {};
      state.ReOrderAddDrawer = true;
      state.ReOrderEditData = action.payload;
      state.ReOrderProductQuality = action.payload.product;
      state.ReProductArray = action?.payload?.product.map(data => data.id);
      state.ReOrderButtonAndModelLabel = 'Re-Order';
    },
    OrderChatDrawerStatus: (state, action) => {
      state.OrderChatAddDrawer = action.payload.status;
      state.ReOrderButtonAndModelLabel = 'Chat';
      state.ReOrderEditData = action.payload.data;
      state.OrderId = action.payload.data.id;
    },
    getOrderChat: (state, action) => {
      state.OrderChatData = action.payload;
      // state.orderChat = action.payload?.additional_product_query;
    },
    setOrderChat: (state, action) => {
      state.orderChat = action.payload?.additional_product_query;
    },
    getChat: (state, action) => {
      state.orderChat = [...state.orderChat, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state, action) => {
        state.OrderButtonSpiner = true;
      }).addCase(createOrder.fulfilled, (state, action) => {
        state.OrderButtonSpiner = false;
        state.OrderProductQuality = [];
        state.OrderErrors = {}
      }).addCase(createOrder.rejected, (state, action) => {
        state.OrderErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.OrderShowMessage = true; // Set the showMessage flag to display the errors
        state.OrderButtonSpiner = false;
      }).addCase(getOrder.pending, (state, action) => {
        // state.OrderButtonSpiner = true;
      }).addCase(getOrder.fulfilled, (state, action) => {
        // state.OrderButtonSpiner = false;
        state.OrderResult = action.payload;
        state.OrderErrors = {}
      }).addCase(getOrder.rejected, (state, action) => {
        state.OrderErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.OrderShowMessage = true; // Set the showMessage flag to display the errors
        state.OrderButtonSpiner = false;
      }).addCase(getOrderFilter.pending, (state, action) => {
        state.OrderButtonSpiner = true;
      }).addCase(getOrderFilter.fulfilled, (state, action) => {
        // state.OrderButtonSpiner = false;
        state.OrderResult = action.payload;
        state.OrderErrors = {}
      }).addCase(getOrderFilter.rejected, (state, action) => {
        state.OrderErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.OrderShowMessage = true; // Set the showMessage flag to display the errors
        state.OrderButtonSpiner = false;
      }).addCase(getAdditionalOrder.pending, (state, action) => {
        // state.OrderButtonSpiner = true;
      }).addCase(getAdditionalOrder.fulfilled, (state, action) => {
        state.additionalOrderData = action.payload;
      }).addCase(getAdditionalOrder.rejected, (state, action) => {
        state.OrderErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(sendMessage.pending, (state, action) => {
        // state.OrderButtonSpiner = true;
      }).addCase(sendMessage.fulfilled, (state, action) => {
        // state.OrderChatData = action.payload;
      }).addCase(sendMessage.rejected, (state, action) => {
        state.OrderErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getAdditionalOrderMessages.pending, (state, action) => {
        // state.OrderButtonSpiner = true;
      }).addCase(getAdditionalOrderMessages.fulfilled, (state, action) => {
        state.additionalOrderData = action.payload;
      }).addCase(getAdditionalOrderMessages.rejected, (state, action) => {
        state.OrderErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getOrderDetail.pending, (state, action) => {
        // state.OrderButtonSpiner = true;
      }).addCase(getOrderDetail.fulfilled, (state, action) => {
        state.orderDetailData = action.payload;
      }).addCase(getOrderDetail.rejected, (state, action) => {
        state.OrderErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      });
  },
});

export const { setOrderChat, getChat, getOrderChat, OrderChatDrawerStatus, ReOrderAddDrawerStatus, OrderProductStatus, OrderAddDrawerStatus, OrderEditWithDrawerStatus, OrderReOrderWithDrawerStatus } = manageOrderSlice.actions;

export default manageOrderSlice.reducer;
