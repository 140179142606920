import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ALL_ORDERS, ALL_ORDERS_API_URL } from 'constants/AppConstants';
import CommonService from 'services/CommonService/CommonService';

export const initialState = {
  OrderErrors: {},
  OrderShowMessage: null,
  OrderResult: [],
  OrderButtonSpiner: false,
  OrderAddDrawer: false,
  OrderEditData: [],
  OrderProductQuality: [],
  productArray: [],
  // OrderButtonAndModelLabel: 'Add Order',
  ReOrderErrors: {},
  ReOrderAddDrawer: false,
  ReOrderEditData: [],
  ReOrderProductQuality: [],
  ReProductArray: [],
  ReOrderButtonAndModelLabel: []
};

export const createOrder = createAsyncThunk(
  "createOrder",
  async (data) => {
    try {
      const response = await CommonService.createAndUpate(data, ALL_ORDERS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getOrder = createAsyncThunk(
  "getOrder",
  async (data) => {
    try {
      const response = await CommonService.getAll(ALL_ORDERS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteOrder = createAsyncThunk(
  "deleteOrder",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, ALL_ORDERS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageAllOrdersSlice = createSlice({
  name: ALL_ORDERS,
  initialState,
  reducers: {
    OrderAddDrawerStatus: (state, action) => {
      state.OrderErrors = {};
      state.OrderAddDrawer = action.payload;
      state.OrderProductQuality = [];
      state.OrderEditData = [];
      state.OrderButtonAndModelLabel = 'Add Order';
      state.productArray = [];
    },
    ReOrderAddDrawerStatus: (state, action) => {
      state.ReOrderErrors = {};
      state.ReOrderAddDrawer = action.payload;
      state.ReOrderProductQuality = [];
      state.ReOrderEditData = [];
      state.ReOrderButtonAndModelLabel = 'Add Order';
      state.ReProductArray = [];
    },
    OrderProductStatus: (state, action) => {
      state.OrderProductQuality = action.payload;
    },
    OrderEditWithDrawerStatus: (state, action) => {
      state.OrderErrors = {};
      state.OrderAddDrawer = true;
      state.OrderEditData = action.payload;
      state.productArray = action?.payload?.product.map(data => data.id);
      state.OrderProductQuality = action.payload.product;
      state.OrderButtonAndModelLabel = 'Edit Order';
    },
    OrderReOrderWithDrawerStatus: (state, action) => {
      state.ReOrderErrors = {};
      state.ReOrderAddDrawer = true;
      state.ReOrderEditData = action.payload;
      state.ReOrderProductQuality = action.payload.product;
      state.ReProductArray = action?.payload?.product.map(data => data.id);
      state.ReOrderButtonAndModelLabel = 'Re-Order';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state, action) => {
        state.OrderButtonSpiner = true;
      }).addCase(createOrder.fulfilled, (state, action) => {
        state.OrderButtonSpiner = false;
        state.OrderProductQuality = [];
        state.OrderErrors = {}
      }).addCase(createOrder.rejected, (state, action) => {
        state.OrderErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.OrderShowMessage = true; // Set the showMessage flag to display the errors
        state.OrderButtonSpiner = false;
      }).addCase(getOrder.pending, (state, action) => {
        state.OrderButtonSpiner = true;
      }).addCase(getOrder.fulfilled, (state, action) => {
        state.OrderButtonSpiner = false;
        state.OrderResult = action.payload;
        state.OrderErrors = {}
      }).addCase(getOrder.rejected, (state, action) => {
        state.OrderErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.OrderShowMessage = true; // Set the showMessage flag to display the errors
        state.OrderButtonSpiner = false;
      });
  },
});

export const { ReOrderAddDrawerStatus, OrderProductStatus, OrderAddDrawerStatus, OrderEditWithDrawerStatus, OrderReOrderWithDrawerStatus } = manageAllOrdersSlice.actions;

export default manageAllOrdersSlice.reducer;
