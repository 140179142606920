import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';
import store from '../store';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { notification } from 'antd';

const unauthorizedCode = [400, 401, 403];

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
});

// Config
const TOKEN_PAYLOAD_KEY = 'authorization';

// API Request interceptor
service.interceptors.request.use(config => {
  const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

  if (jwtToken) {
    config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
  }

  return config;
}, error => {
  // Do something with request error here
  notification.error({
    message: 'Error'
  });
  return Promise.reject(error);
});

// API response interceptor
service.interceptors.response.use(
  response => {
    if (response.status >= 200 && response.status < 300) {
      // Exclude notifications for successful responses (2xx)
      let notificationParam = {
        message: response.data.message
      };
      if (response.data.data == false) {

        notification.success(notificationParam);
      }

      return response.data;
    }
    // Show notifications for delete, update, insert data
    notification.success({
      message: response.data.message
    });
    return response.data;
  },
  error => {
    let notificationParam = {
      message: ''
    };

    // Remove token and redirect 
    if (unauthorizedCode.includes(error.response.status)) {
      notificationParam.message = 'Invalid email or password.';
      notificationParam.description = 'Please login again';
      localStorage.removeItem(AUTH_TOKEN);
      store.dispatch(signOutSuccess());
    }

    if (error.response.status === 404) {
      notificationParam.message = 'Not Found';
    }

    if (error.response.status === 401) {
      notificationParam.message = 'Invalid email or password.';
    }

    if (error.response.status === 500) {
      notificationParam.message = 'Internal Server Error';
    }

    if (error.response.status === 508) {
      notificationParam.message = 'Time Out';
    }

    if (error.response.status === 422) {
      notificationParam.message = 'Validation error';
    }
    if (error.response.status != 422) {

      notification.error(notificationParam);
    }

    return Promise.reject(error);
  }
);

export default service;
