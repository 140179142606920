import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IMPORT_EXCEL, IMPORT_CUSTOMER_EXCEL_API_URL, IMPORT_PRODUCT_EXCEL_API_URL, IMPORT_ORDER_EXCEL_API_URL } from 'constants/AppConstants';
import CommonService from 'services/CommonService/CommonService';

export const initialState = {
  ImportExcelButtonSpiner: false,
  loading: false,
  errors: {},
  showMessage: false,
  unSaveCustomers: [],
  unSaveOrders: [],
  unSaveProducts: [],
};

export const importCustomerExcel = createAsyncThunk(
  "importCustomerExcel",
  async (data) => {
    try {
      const response = await CommonService.createAndUpate(data, IMPORT_CUSTOMER_EXCEL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const importOrderExcel = createAsyncThunk(
  "importOrderExcel",
  async (data) => {
    try {
      const response = await CommonService.createAndUpate(data, IMPORT_ORDER_EXCEL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const importProductExcel = createAsyncThunk(
  "importProductExcel",
  async (data) => {
    try {
      const response = await CommonService.createAndUpate(data, IMPORT_PRODUCT_EXCEL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageImportExcelSlice = createSlice({
  name: IMPORT_EXCEL,
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(importCustomerExcel.pending, (state, action) => {
        state.ImportExcelButtonSpiner = true;
        state.unSaveCustomers = [];
      }).addCase(importCustomerExcel.fulfilled, (state, action) => {
        state.ImportExcelButtonSpiner = false;
        state.unSaveCustomers = action?.payload?.data?.unSaveCustomers
      }).addCase(importCustomerExcel.rejected, (state, action) => {
        state.ImportExcelButtonSpiner = false;
      })
      .addCase(importOrderExcel.pending, (state, action) => {
        state.ImportExcelButtonSpiner = true;
        state.unSaveOrders = [];
      }).addCase(importOrderExcel.fulfilled, (state, action) => {
        state.ImportExcelButtonSpiner = false;
        state.unSaveOrders = action?.payload?.data?.unSaveOrders
      }).addCase(importOrderExcel.rejected, (state, action) => {
        state.ImportExcelButtonSpiner = false;
      })
      .addCase(importProductExcel.pending, (state, action) => {
        state.ImportExcelButtonSpiner = true;
        state.unSaveProducts = [];
      }).addCase(importProductExcel.fulfilled, (state, action) => {
        state.ImportExcelButtonSpiner = false;
        state.unSaveProducts = action?.payload?.data?.unSaveProducts
      }).addCase(importProductExcel.rejected, (state, action) => {
        state.ImportExcelButtonSpiner = false;
      })
  },
});

export const { } = manageImportExcelSlice.actions;

export default manageImportExcelSlice.reducer;
