import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CREATE_PERMISSION, NAME_OF_PERMISSION_SLICE, GET_PERMISSION, DELETE_PERMISSION } from 'constants/AppConstants';
import PermissionService from 'services/Permissions/PermissionService';

export const initialState = {
    errors: {},
    showMessage: '',
    permissionResult: [],
    permissionButtonSpiner:false,
    AddPermissionDrawer: false,
    editPermissionData: [],
    modalLabel:'Add Permission'
  };
  export const createPermission = createAsyncThunk(
    CREATE_PERMISSION,
    async (data) => {
      try {
        const response = await PermissionService.createPermission(data);
        return response;
      } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
      }
    }
  );

  export const getPermission = createAsyncThunk(
    GET_PERMISSION,
    async () => {
      try {
        const response = await PermissionService.getPermission();
        return response;
      } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
      }
    }
  );

  export const deletePermission = createAsyncThunk(
    DELETE_PERMISSION,
    async (data) => {
      try {
        const response = await PermissionService.deletePermission(data);
        return response;
      } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
      }
    }
  );
  
  export const managePermissionSlice = createSlice({
    name: NAME_OF_PERMISSION_SLICE,
    initialState,
    reducers: {
      AddPermissionDrawerStatus: (state, action) => {
        state.errors = {};
        state.AddPermissionDrawer = action.payload;
        state.editPermissionData = [];
        state.modalLabel = 'Add Permission';
      },
      EditPermission: (state, action) => {
         state.errors = {};
         state.AddPermissionDrawer = true;
         state.editPermissionData = action.payload;
         state.modalLabel = 'Edit Permission';
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(createPermission.pending, (state, action) => {
          state.loading = true;
          state.permissionButtonSpiner = true;
        })
        .addCase(createPermission.fulfilled, (state, action) => {
          state.permissionButtonSpiner = false;
          state.errors={};
        })
        .addCase(createPermission.rejected, (state, action) => {
          state.errors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
          state.showMessage = true; // Set the showMessage flag to display the errors
          state.permissionButtonSpiner = false;
        })
        .addCase(getPermission.pending, (state, action) => {
          state.loading = true;
          state.permissionButtonSpiner = true;
        })
        .addCase(getPermission.fulfilled, (state, action) => {
          state.permissionResult = action.payload;
          state.permissionButtonSpiner = false;
        })
        .addCase(getPermission.rejected, (state, action) => {
          state.errors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
          state.showMessage = true; // Set the showMessage flag to display the errors
          state.permissionButtonSpiner = false;
        })
        .addCase(deletePermission.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(deletePermission.fulfilled, (state, action) => {
          state.loading = true;
        })
        .addCase(deletePermission.rejected, (state, action) => {
          state.loading = true;
          
        });
    },
  });
  
  export const { AddPermissionDrawerStatus, EditPermission, CancelEditPermission} = managePermissionSlice.actions;
  export default managePermissionSlice.reducer;  