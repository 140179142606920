import fetch from 'auth/FetchInterceptor'

const AllBlogs = {}

AllBlogs.createAllBlogs= function (data) {
	if (data && data.hasOwnProperty('id')) {
	  return fetch({
		url: `all-blogs/${data.id}`,
		method: 'put',
		data
	  });
	} else {
	  return fetch({
		url: 'all-blogs',
		method: 'post',
		data
	  });
	}
  };
//
AllBlogs.getAllBlogs= function () {
	
	return fetch({
		url: 'all-blogs',
		method: 'get',
	})
}
//
AllBlogs.showAllBlogs= function (id) {
	
	return fetch({
		url: `all-blogs/${id}`,
		method: 'get',
	})
}
//
AllBlogs.deleteAllBlogs= function (id) {
    return fetch({
        url: `all-blogs/${id}`,
        method: 'delete',
    });
};


export default AllBlogs;