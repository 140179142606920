import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CREATE_ROLE,DELETE_ROLE,GET_ROLE, NAME_OF_ROLE_SLICE } from 'constants/AppConstants';
import Roles from 'services/Roles/Roles';

export const initialState = {
  RolesErrors       : {},
  RolesShowMessage  : null,
  RolesResult       : [],
  RolesButtonSpiner : false,
  RolesAddDrawer    : false,
  RolesEditData     : [],
  RolesButtonAndModelLabel  : 'Add Role',
};

export const createRoles = createAsyncThunk(
    CREATE_ROLE,
  async (data) => {
    try {
      const response = await Roles.createRoles(data);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getRoles = createAsyncThunk(
    GET_ROLE,
  async (data) => {
    try {
      const response = await Roles.getRoles(data);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteRoles = createAsyncThunk(
  DELETE_ROLE,
  async (data) => {
    try {
      const response = await Roles.deleteRoles(data);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageRolesSlice = createSlice({
  name: NAME_OF_ROLE_SLICE,
  initialState,
  reducers: {
    RolesAddDrawerStatus: (state, action) => {
      state.RolesErrors      = {};
      state.RolesAddDrawer   = action.payload;
      state.RolesEditData    = [];
      state.RolesButtonAndModelLabel = 'Add Role';
    },
    RolesEditWithDrawerStatus: (state, action) => {
      state.RolesErrors = {};
      state.RolesAddDrawer = true;
      state.RolesEditData = action.payload;
      state.RolesButtonAndModelLabel = 'Edit Role';

   }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRoles.pending, (state, action) => {
        state.RolesButtonSpiner = true;
      }).addCase(createRoles.fulfilled, (state, action) => {
        state.RolesButtonSpiner = false;
        state.RolesErrors = {}
      }).addCase(createRoles.rejected, (state, action) => {
        state.RolesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.RolesShowMessage = true; // Set the showMessage flag to display the errors
        state.RolesButtonSpiner = false;
      }).addCase(getRoles.pending, (state, action) => {
        state.RolesButtonSpiner = true;
      }).addCase(getRoles.fulfilled, (state, action) => {
        state.RolesButtonSpiner = false;
        state.RolesResult = action.payload;
        state.RolesErrors = {}
      }).addCase(getRoles.rejected, (state, action) => {
        state.RolesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.RolesShowMessage = true; // Set the showMessage flag to display the errors
        state.RolesButtonSpiner = false;
      });
  },
});

export const {  RolesAddDrawerStatus, RolesEditWithDrawerStatus } = manageRolesSlice.actions;

export default manageRolesSlice.reducer;
