import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ORGANIZATION, ORGANIZATION_API_URL } from "constants/AppConstants";
import CommonService from "services/CommonService/CommonService";

export const initialState = {
  OrganizationErrors: {},
  OrganizationShowMessage: null,
  OrganizationResult: [],
  OrganizationButtonSpiner: false,
  OrganizationAddDrawer: false,
  OrganizationEditData: [],
  OrganizationButtonAndModelLabel: "Add Organization",
  OrganizationRoleResult: [],
};

export const createOrganization = createAsyncThunk(
  "createOrganization",
  async (data) => {
    try {
      const response = await CommonService.createAndUpate(
        data,
        ORGANIZATION_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getOrganization = createAsyncThunk(
  "getOrganization",
  async (data) => {
    try {
      const response = await CommonService.getAll(ORGANIZATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getOrganizationRoles = createAsyncThunk(
  "getOrganizationRole",
  async (data) => {
    try {
      const response = await CommonService.showOne(data, ORGANIZATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteOrganization = createAsyncThunk(
  "deleteOrganization",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(
        data,
        ORGANIZATION_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageOrganizationSlice = createSlice({
  name: ORGANIZATION,
  initialState,
  reducers: {
    OrganizationAddDrawerStatus: (state, action) => {
      state.OrganizationErrors = {};
      state.OrganizationAddDrawer = action.payload;
      state.OrganizationEditData = [];
      state.OrganizationButtonAndModelLabel = "Add Organization";
    },
    OrganizationEditWithDrawerStatus: (state, action) => {
      console.log(action);
      state.OrganizationErrors = {};
      state.OrganizationAddDrawer = true;
      state.OrganizationEditData = action.payload;
      state.OrganizationButtonAndModelLabel = "Edit Organization";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrganization.pending, (state, action) => {
        state.OrganizationButtonSpiner = true;
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.OrganizationButtonSpiner = false;
        state.OrganizationErrors = {};
      })
      .addCase(createOrganization.rejected, (state, action) => {
        state.OrganizationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.OrganizationShowMessage = true; // Set the showMessage flag to display the errors
        state.OrganizationButtonSpiner = false;
      })
      .addCase(getOrganization.pending, (state, action) => {
        state.OrganizationButtonSpiner = true;
      })
      .addCase(getOrganization.fulfilled, (state, action) => {
        state.OrganizationButtonSpiner = false;
        state.OrganizationResult = action.payload;
        state.OrganizationErrors = {};
      })
      .addCase(getOrganization.rejected, (state, action) => {
        state.OrganizationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.OrganizationShowMessage = true; // Set the showMessage flag to display the errors
        state.OrganizationButtonSpiner = false;
      })
      .addCase(getOrganizationRoles.pending, (state, action) => {
        state.OrganizationButtonSpiner = true;
      })
      .addCase(getOrganizationRoles.fulfilled, (state, action) => {
        state.OrganizationButtonSpiner = false;
        state.OrganizationRoleResult = action.payload;
        state.OrganizationErrors = {};
      })
      .addCase(getOrganizationRoles.rejected, (state, action) => {
        state.OrganizationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.OrganizationShowMessage = true; // Set the showMessage flag to display the errors
        state.OrganizationButtonSpiner = false;
      });
  },
});

export const { OrganizationAddDrawerStatus, OrganizationEditWithDrawerStatus } =
  manageOrganizationSlice.actions;

export default manageOrganizationSlice.reducer;
