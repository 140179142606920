import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_CATEGORY, PRODUCT_CATEGORY_API_URL, PRODUCT_CATEGORIES_API_URL } from 'constants/AppConstants';
import CommonService from 'services/CommonService/CommonService';

export const initialState = {
  ProductCategoryErrors: {},
  ProductCategoryShowMessage: null,
  ProductCategoryResult: [],
  ProductCategoryButtonSpiner: false,
  ProductCategoryAddDrawer: false,
  ProductCategoryEditData: [],
  ProductCategoryButtonAndModelLabel: 'Add Product Category',
  CategoryProductData: [],
  CategoryProductSpinner: false,
};

export const createProductCategory = createAsyncThunk(
  "createProductCategory",
  async (data) => {
    try {
      const response = await CommonService.createAndUpate(data, PRODUCT_CATEGORY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getProductCategory = createAsyncThunk(
  "getProductCategory",
  async (data) => {
    try {
      const response = await CommonService.getAll(PRODUCT_CATEGORY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteProductCategory = createAsyncThunk(
  "deleteProductCategory",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, PRODUCT_CATEGORY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getCategoryProduct = createAsyncThunk(
  "getCategoryProduct",
  async (data) => {
    try {
      const response = await CommonService.showOne(data, PRODUCT_CATEGORY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);


export const getCategoriesProduct = createAsyncThunk(
  "getCategoriesProduct",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, PRODUCT_CATEGORIES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);


export const manageProductCategorySlice = createSlice({
  name: PRODUCT_CATEGORY,
  initialState,
  reducers: {
    ProductCategoryAddDrawerStatus: (state, action) => {
      state.ProductCategoryErrors = {};
      state.ProductCategoryAddDrawer = action.payload;
      state.ProductCategoryEditData = [];
      state.ProductCategoryButtonAndModelLabel = 'Add Product Category';
    },
    ProductCategoryEditWithDrawerStatus: (state, action) => {
      state.ProductCategoryErrors = {};
      state.ProductCategoryAddDrawer = true;
      state.ProductCategoryEditData = action.payload;
      state.ProductCategoryButtonAndModelLabel = 'Edit Product Category';

    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProductCategory.pending, (state, action) => {
        state.ProductCategoryButtonSpiner = true;
      }).addCase(createProductCategory.fulfilled, (state, action) => {
        state.ProductCategoryButtonSpiner = false;
        state.ProductCategoryErrors = {}
      }).addCase(createProductCategory.rejected, (state, action) => {
        state.ProductCategoryErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.ProductCategoryShowMessage = true; // Set the showMessage flag to display the errors
        state.ProductCategoryButtonSpiner = false;
      }).addCase(getProductCategory.pending, (state, action) => {
        state.ProductCategoryButtonSpiner = true;
      }).addCase(getProductCategory.fulfilled, (state, action) => {
        state.ProductCategoryButtonSpiner = false;
        state.ProductCategoryResult = action.payload;
        state.ProductCategoryErrors = {}
      }).addCase(getProductCategory.rejected, (state, action) => {
        state.ProductCategoryErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.ProductCategoryShowMessage = true; // Set the showMessage flag to display the errors
        state.ProductCategoryButtonSpiner = false;
      })
      .addCase(getCategoryProduct.pending, (state, action) => {
        state.CategoryProductSpinner = true;
      }).addCase(getCategoryProduct.fulfilled, (state, action) => {
        state.CategoryProductSpinner = false;
        state.CategoryProductData = action.payload;
      }).addCase(getCategoryProduct.rejected, (state, action) => {
        state.CategoryProductSpinner = false;
      })
      .addCase(getCategoriesProduct.pending, (state, action) => {
        state.CategoryProductSpinner = true;
      }).addCase(getCategoriesProduct.fulfilled, (state, action) => {
        state.CategoryProductSpinner = false;
        state.CategoryProductData = action.payload;
      }).addCase(getCategoriesProduct.rejected, (state, action) => {
        state.CategoryProductSpinner = false;
      });
  },
});

export const { ProductCategoryAddDrawerStatus, ProductCategoryEditWithDrawerStatus } = manageProductCategorySlice.actions;

export default manageProductCategorySlice.reducer;
