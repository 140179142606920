import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CUSTOMER, CUSTOMER_API_URL, CUSTOMER_ORDER_API_URL, CUSTOMER_ORDER_FILTER_API_URL, CUSTOMER_PRODUCT_API_URL } from 'constants/AppConstants';
import CommonService from 'services/CommonService/CommonService';

export const initialState = {
  CustomerErrors: {},
  CustomerShowMessage: null,
  CustomerResult: [],
  CustomerButtonSpiner: false,
  CustomerProductSpinner: true,
  CustomerAddDrawer: false,
  customerViewModal: false,
  CustomerViewData: [],
  CustomerEditData: [],
  CustomerProductData: [],
  CustomerOrderData: [],
  CustomerButtonAndModelLabel: 'Add Customer',
};

export const createCustomer = createAsyncThunk(
  "createCustomer",
  async (data) => {
    try {
      const response = await CommonService.createAndUpate(data, CUSTOMER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getCustomer = createAsyncThunk(
  "getCustomer",
  async (data) => {
    try {
      const response = await CommonService.getAll(CUSTOMER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getCustomerProduct = createAsyncThunk(
  "getCustomerProduct",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, CUSTOMER_PRODUCT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "deleteCustomer",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, CUSTOMER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getSingleCustomer = createAsyncThunk(
  "getSingleCustomer",
  async (data) => {
    try {
      const response = await CommonService.showOne(data, CUSTOMER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getCustomerOrders = createAsyncThunk(
  "getCustomerOrders",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, CUSTOMER_ORDER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);


export const getCustomerFilter = createAsyncThunk("getCustomerFilter", async (data) => {
  try {
    const response = await CommonService.getAllPost(data, CUSTOMER_ORDER_FILTER_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const manageCustomerSlice = createSlice({
  name: CUSTOMER,
  initialState,
  reducers: {
    CustomerAddDrawerStatus: (state, action) => {
      state.CustomerErrors = {};
      state.CustomerAddDrawer = action.payload;
      state.CustomerEditData = [];
      state.CustomerButtonAndModelLabel = 'Add Customer';
    },
    CustomerEditWithDrawerStatus: (state, action) => {
      state.CustomerErrors = {};
      state.CustomerAddDrawer = true;
      state.CustomerEditData = action.payload;
      state.CustomerButtonAndModelLabel = 'Edit Customer';
    },
    customerViewModalStatus: (state, action) => {
      console.log(action.payload.data);
      state.CustomerViewData = action.payload.data;
      state.customerViewModal = action.payload.status;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCustomer.pending, (state, action) => {
        state.CustomerButtonSpiner = true;
      }).addCase(createCustomer.fulfilled, (state, action) => {
        state.CustomerButtonSpiner = false;
        state.CustomerErrors = {}
      }).addCase(createCustomer.rejected, (state, action) => {
        state.CustomerErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.CustomerShowMessage = true; // Set the showMessage flag to display the errors
        state.CustomerButtonSpiner = false;
      }).addCase(getCustomer.pending, (state, action) => {
        state.CustomerButtonSpiner = true;
      }).addCase(getCustomer.fulfilled, (state, action) => {
        state.CustomerButtonSpiner = false;
        state.CustomerResult = action.payload;
      }).addCase(getCustomer.rejected, (state, action) => {
        state.CustomerErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.CustomerShowMessage = true; // Set the showMessage flag to display the errors
        state.CustomerButtonSpiner = false;
      }).addCase(getCustomerProduct.pending, (state, action) => {
        state.CustomerProductSpinner = true;
      }).addCase(getCustomerProduct.fulfilled, (state, action) => {
        state.CustomerProductSpinner = (action.payload).length > 0 ? false : true;
        state.CustomerProductData = action.payload;
      }).addCase(getCustomerProduct.rejected, (state, action) => {
        state.CustomerErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.CustomerShowMessage = true; // Set the showMessage flag to display the errors
        state.CustomerProductSpinner = true;
      })
      .addCase(getCustomerOrders.pending, (state, action) => {
      }).addCase(getCustomerOrders.fulfilled, (state, action) => {
        state.CustomerOrderData = action.payload;
      }).addCase(getCustomerOrders.rejected, (state, action) => {
        state.CustomerErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(getCustomerFilter.pending, (state, action) => {
        state.CustomerButtonSpiner = true;
      }).addCase(getCustomerFilter.fulfilled, (state, action) => {
        state.CustomerButtonSpiner = false;
        state.CustomerResult = action.payload;
      }).addCase(getCustomerFilter.rejected, (state, action) => {
        state.CustomerErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.CustomerShowMessage = true; // Set the showMessage flag to display the errors
        state.CustomerButtonSpiner = false;
      })
      .addCase(getSingleCustomer.pending, (state, action) => {
      }).addCase(getSingleCustomer.fulfilled, (state, action) => {
        state.CustomerViewData = action.payload;
      }).addCase(getSingleCustomer.rejected, (state, action) => {
        state.CustomerErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })


  },
});

export const { customerViewModalStatus, CustomerAddDrawerStatus, CustomerEditWithDrawerStatus } = manageCustomerSlice.actions;

export default manageCustomerSlice.reducer;
