import fetch from "auth/FetchInterceptor";

const CommonService = {};

CommonService.createAndUpate = function (data, request_url) {
  if (data && data.hasOwnProperty("id")) {
    return fetch({
      url: `${request_url}/${data.id}`,
      method: "put",
      data,
    });
  } else {
    return fetch({
      url: `${request_url}`,
      method: "post",
      data,
    });
  }
};
//
CommonService.getAll = function (request_url) {
  return fetch({
    url: `${request_url}`,
    method: "get",
  });
};

//
CommonService.getAllPost = function (data, request_url) {
  return fetch({
    url: `${request_url}`,
    method: "post",
    data,
  });
};

//
CommonService.showOne = function (id, request_url) {
  return fetch({
    url: `${request_url}/${id}`,
    method: "get",
  });
};
//
CommonService.deleteOne = function (id, request_url) {
  return fetch({
    url: `${request_url}/${id}`,
    method: "delete",
  });
};

export default CommonService;
